<template>
  <a-row :gutter="24" type="flex">
    <a-col :span="24" class="mb-24">
      <a-card
        :bordered="false"
        class="header-solid h-full"
        :bodyStyle="{ padding: 0 }"
      >
        <template #title>
          <a-row type="flex" align="middle">
            <a-col :span="24" :md="12" class="top-left-header">
              <h5 class="font-semibold m-0">
                Feature List
                <a-spin v-if="confirmLoading" />
              </h5>
            </a-col>
            <a-col :span="24" :md="12" class="top-right-header">
              <a-button>
                <router-link to="/new-feature-store"
                  >Add New Feature</router-link
                >
              </a-button>
            </a-col>
          </a-row>
        </template>
        <a-table
          :columns="columnData"
          :data-source="featureList"
          :pagination="false"
        >
          <template slot="updateBtn" slot-scope="row">
            <div>
              <a-button type="link" :data-id="row.key">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  @click="updateFeature(row.details.id)"
                >
                  <path
                    class="fill-gray-7"
                    d="M13.5858 3.58579C14.3668 2.80474 15.6332 2.80474 16.4142 3.58579C17.1953 4.36683 17.1953 5.63316 16.4142 6.41421L15.6213 7.20711L12.7929 4.37868L13.5858 3.58579Z"
                  />
                  <path
                    class="fill-gray-7"
                    d="M11.3787 5.79289L3 14.1716V17H5.82842L14.2071 8.62132L11.3787 5.79289Z"
                  />
                </svg>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-trash"
                  viewBox="0 0 16 16"
                  @click="deleteNotification(row.details.id)"
                >
                  <path
                    d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"
                  />
                  <path
                    fill-rule="evenodd"
                    d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                  />
                </svg>
              </a-button>
            </div>
          </template>
        </a-table>

        <div class="pagination">
          <a-pagination
            v-model:current="currentPage"
            :total="pagination"
            :page-size="10"
            :hideOnSinglePage="true"
            @change="onPageChange"
          />
        </div>
      </a-card>
    </a-col>
  </a-row>
</template>

<script>
import { sendRequest } from "@/http/axios.method";
import { message } from "ant-design-vue";

const COLUMN_DATA = [
  {
    title: "ID",
    dataIndex: "id",
    scopedSlots: { customRender: "id" },
    width: 90,
  },
  {
    title: "Name",
    dataIndex: "name",
    width: 200,
  },
  {
    title: "Title",
    dataIndex: "title",
    width: 200,
  },
  {
    title: "Body",
    dataIndex: "body",
    class: "font-semibold text-muted",
  },
  {
    title: "Parent",
    dataIndex: "parent",
    class: "font-semibold text-muted",
  },
  {
    title: "Action",
    scopedSlots: { customRender: "updateBtn" },
    width: 200,
  },
];

export default {
  name: "Feature",
  data() {
    return {
      config: {
        Authorization: "Bearer " + this.$store.state.authUser.token,
      },
      columnData: COLUMN_DATA,
      features: [],
      projectHeaderBtns: "all",
      currentPage: 1,
      pagination: 0,
      confirmLoading: false,
    };
  },
  created() {
    this.getFeatureList();
  },
  methods: {
    onPageChange() {
      this.getFeatureList();
    },
    getFeatureList() {
      this.confirmLoading = true;
      let data = {
        page: this.currentPage,
      };
      sendRequest("get", "admin/features", data, this.config)
        .then((response) => {
          this.confirmLoading = false;
          if (response.success) {
            let data = response.data.features.data;
            this.features = data.map(function (content, label) {
              return {
                key: label + 1,
                id: content.id,
                name: content.name,
                title: content.title,
                body: content.body,
                parent: content.parent ? content.parent.name : "",
                created_at: content.created_at,
                details: content,
              };
            });
            this.pagination = response.data.features.total;
            this.currentPage = response.data.features.current_page;
            if (this.responseMessage > "") {
              message.success(this.responseMessage);
            }
          } else {
            if (response.status_code === 401) {
              this.$store.dispatch("logout");
              this.$router.push("/login");
            }
          }
        })
        .catch(() => {
          this.confirmLoading = false;
        })
        .finally(() => {
          this.confirmLoading = false;
        });
    },
    deleteNotification(id) {
      const apiUrl = `admin/feature/${id}`;
      sendRequest("delete", apiUrl, {}, this.config)
        .then((response) => {
          // console.log(response.message);
          if (response.success) {
            message.info(response.message);
            this.getFeatureList();
          } else {
            message.error(response.data);

            if (response.status_code === 401) {
              this.$store.dispatch("logout");
              this.$router.push("/login");
            }
          }
        })
        .catch(() => {})
        .finally(() => {});
    },
    updateFeature(feature_id) {
      this.$router.push({
        path: "/feature-update",
        query: { id: feature_id },
      });
    },
  },
  computed: {
    featureList: function () {
      return this.features;
    },
    responseMessage() {
      const message = this.$store.state.message;
      this.$store.commit("resetMessage");
      return message;
    },
  },
};
</script>
